import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Quicklinks from '../../../components/Quicklinks';
import Image from '../../../components/Image';
import Icon from '../../../components/Icon';
import Video from '../../../components/Video';

const DiffusionWithMissAmerica2020 = () => (
  <Layout
    title="Diffusion with Miss America 2020 - Science at Home"
    className="science-starters has-video"
  >
    {/* Intro Title and Video */}
    <Section className="science-starters__video">
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../getting-started">Science Starters</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../science-at-home">Science at Home</Link>
          </li>
          <li className="breadcrumb active">
            Diffusion with Miss America 2020
          </li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">Diffusion with Miss America 2020</h1>
            {/* Video Container */}
            <div className="video-container__with-pager">
              <Link to="../push-and-pull" className="previous">
                <Icon name="arrowleftgray" />
              </Link>
              <Video
                guidSrc="31cc54cd-4407-47d8-9e08-be8c1c1b45fa"
                className="de-vid"
              ></Video>
              <Link to="../feeling-sound" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Main Content */}
    <Section className="pt-0 pb-6">
      <Container>
        <Row className="mb-4">
          <Column size={8} offset={1}>
            <h2 className="science-at-home__intro">
              Ever wonder why things mix (or don’t mix) differently in different
              temperatures of water? Join Camille Schrier, a scientist who was
              crowned Miss America 2020, as she explains how substances move
              through water.
            </h2>
          </Column>
        </Row>
        <Row>
          <Column size={8} offset={1} className="science-home__content">
            {/* Quick Links */}
            <Quicklinks
              className="pl-0"
              title="Quicklinks:"
              smoothScroll
              links={[
                {
                  target: '#background',
                  label: 'Background',
                },
                {
                  target: '#key-concepts',
                  label: 'Key Concepts',
                },
                {
                  target: '#materials',
                  label: 'Materials',
                },
                {
                  target: '#safety',
                  label: 'Safety',
                },
                {
                  target: '#standards',
                  label: 'Standards',
                },
              ]}
            />
            {/* Background */}
            <h3 id="background">Background</h3>
            <p>
              Diffusion is the mixing of substances due to the movement of their
              particles. This can occur with all sorts of matter, but is most
              commonly observed in liquids and gases. Typically, diffusion
              refers to the movement of molecules from high concentrations to
              lower concentrations. The rate of this movement depends on the
              energy of the molecules. Think of how delicious smells from your
              kitchen take longer to get into your bedroom than to your living
              room which is located right beside the kitchen. That is because
              the molecules from the food odor haven't diffused into that space
              yet. In this experiment, you will experience how temperature
              affects the mixing of food coloring drops with water.
            </p>
            {/* Key Concepts */}
            <h3 id="key-concepts">Key Concepts</h3>
            <ul>
              <li>Diffusion</li>
              <li>Temperature and Molecules</li>
            </ul>
            {/* Materials */}
            <h3 id="materials">Materials</h3>
            <ul>
              <li>An adult helper</li>
              <li>Two clear glasses</li>
              <li>Water</li>
              <li>Food coloring</li>
            </ul>
            <h3>Preparation</h3>
            <ul>
              <li>
                Fill one glass half full of the coldest water you can get from
                the tap
              </li>
              <li>
                Fill the other glass the same amount with the hottest water you
                can get from the tap
              </li>
              <li>
                Make a prediction. What might be different about the way the
                food coloring moves through the cold water and the hot water?
              </li>
              <li>
                At the exact same time, add 2-3 drops off food coloring into
                each glass. Do not mix or stir.
              </li>
              <li>
                Watch what happens to the food coloring. Does it move
                differently in the hot water than the cold water?
              </li>
              <li>
                Be sure to clean up when you are done. Wipe up any spills that
                may have happened. Pour all of the water down the sink, and wash
                out the glasses. Put the food coloring back where you found it.
              </li>
            </ul>
            <h3>Observation and Results</h3>
            <p>
              The food coloring mixes through the hot water faster than it mixes
              with the cold water. This is because in hot water, the water
              molecules have more energy and are moving faster than the
              molecules of cold water. This makes it easier for the dye to get
              mixed throughout the hot water. Because diffusion happens from
              high concentration to low concentration, the more molecules are
              moving, the more opportunities they have to mix together. The high
              energy hot water model diffusion is important because it’s how we
              get oxygen to all the cells in our bodies. When deoxygenated blood
              is in your capillaries in your lungs, the oxygen in your lungs
              diffuses from a higher concentration in your lungs to the lower
              concentration in your blood. This allows red blood cells to carry
              oxygen all over your body.
            </p>
            <p>
              In this experiment, the food coloring that gets added is the same
              temperature in each of the hot and cold water tests. Do you think
              the temperature of the food coloring might have an effect on how
              it diffuses? Can you find a safe way to heat or cool the food
              coloring before adding it to the water? Can you predict, based on
              the first way of doing the experiment, what might change, if
              anything?
            </p>
            <p>
              Also, think about where you see this happen in other places. For
              example, how might this play into why coffee and tea always start
              with hot water? If you find an example of where hot or cold water
              is used for something that might involve diffusion, see if you can
              try it with the opposite and observe any differences in the
              results.{' '}
            </p>
            {/* Safety */}
            <h3 id="safety">Safety First and Adult Supervision</h3>
            <ul>
              <li>Follow the experiment’s instructions carefully.</li>
              <li>A responsible adult should assist with each experiment.</li>
              <li>
                While science experiments at home are exciting ways to learn
                about science hands-on, please note that some may require
                participants to take extra safety precautions and/or make a
                mess.
              </li>
              <li>
                Adults should handle or assist with potentially harmful
                materials or sharp objects.
              </li>
              <li>
                Adult should review each experiment and determine what the
                appropriate age is for the student’s participation in each
                activity before conducting any experiment.
              </li>
            </ul>
            {/* Standards */}
            <h3 id="standards">
              Next Generation Science Standard (NGSS) Supported - Disciplinary
              Core Ideas
            </h3>
            <p>
              This experiment was selected for Science at Home because it
              teaches NGSS Disciplinary Core Ideas, which have broad importance
              within or across multiple science or engineering disciplines.
            </p>
            <p>
              Learn more about how this experiment is based in NGSS Disciplinary
              Core Ideas.
            </p>
            <p className="bold">
              Engineering Design (ETS)1: Engineering Design
            </p>
            <div className="accordion">
              <div className="accordion__group">
                <div className="accordion__item">
                  <input type="checkbox" id="chck1"></input>
                  <label className="label" for="chck1">
                    PS1.A: Structure and Properties of Matter
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>2-PS1-1.</em> Different kinds of matter exist and
                        can be described by its observable properties.
                      </li>
                      <li>
                        <em>2-PS1-2.</em> Different properties are suited to
                        different purposes.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>5-PS1-1.</em> Matter of any type can be subdivided
                        into particles that are too small to see, but event then
                        the matter still exists and can be detected by other
                        means.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS1-1.</em> Atoms form molecules that range in
                        size from two to thousands of atoms. Molecules may be
                        extended structures with repeating subunits.
                      </li>
                      <li>
                        <em>MS-PS1-2.</em> Each pure substance has
                        characteristic physical and chemical properties that can
                        be used to identify it.
                      </li>
                      <li>
                        <em>MS-PS1-4.</em> In a solid, atoms are closely spaced
                        and may vibrate in position but do not change relative
                        locations.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS1-1.</em> Each atom has a charged substructure
                        consisting of a nucleus, which is made of protons and
                        neutrons, surrounded by electrons.
                      </li>
                      <li>
                        <em>HS-PS1-2.</em> The periodic table orders elements
                        horizontally by the number of protons in the atom's
                        nucleus and places those with similar chemical
                        properties in columns. The repeating patterns of this
                        table reflect pattern of outer electron states.
                      </li>
                      <li>
                        <em>HS-PS1-3.</em> The structure and interactions of
                        matter at the bulk scale are determined by electrical
                        forces within and between atoms.
                      </li>
                      <li>
                        <em>HS-PS1-4.</em> Stable forms of matter are those in
                        which the electric and magnetic field energy is
                        minimized.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck2"></input>
                  <label className="label" for="chck2">
                    PS2.A: Forces and Motion
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>K-PS2-1.</em> Pushes and pulls can have different
                        strengths and directions.
                      </li>
                      <li>
                        <em>K-PS2-2.</em> Pushing or pulling on an object can
                        change the speed or direction of its motion and can
                        start or stop it.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>3-PS2-1.</em> Each force acts on one particular
                        object and has both strength and a direction. An object
                        at rest typically has multiple forces acting on it, but
                        they add to give zero net force on the object. Forces
                        that do not sum to zero can cause changes in the
                        object’s speed or direction of motion.
                      </li>
                      <li>
                        <em>3-PS2-2.</em> The patterns of an object's motion in
                        various situations can be observed and measured; when
                        that past motion exhibits a regular pattern, future
                        motion can be predicted from it.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS2-1.</em> For any pair of interacting objects,
                        the force exerted by the first object on the second
                        object is equal in strength to the force that the second
                        object exerts on the first, but in the opposite
                        direction.
                      </li>
                      <li>
                        <em>MS-PS2-2.</em> The motion of an object is determined
                        by the sum of the forces acting on it; if the total
                        force on the object is not zero its motion will change.
                        The greater the mass of the object, the greater the
                        force needed to achieve the same change in motion. For
                        any given object, a larger force causes a larger change
                        in motion.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS2-1.</em> Newton’s second law accurately
                        predicts changes in the motion of macroscopic objects.
                      </li>
                      <li>
                        <em>HS-PS2-2.</em> Momentum is defined for a particular
                        frame of reference; it is the ass times the velocity of
                        the object. In any system, total momentum is always
                        conserved.
                      </li>
                      <li>
                        <em>HS-PS1-3.</em> If a system interacts with objects
                        outside itself, the total momentum of the system can
                        change; however, any such change is balanced by changes
                        in the moment of objects outside the system.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck3"></input>
                  <label className="label" for="chck3">
                    PS2.B: Types of Interactions
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>K-PS2-1.</em> When objects touch or collide, they
                        push on one another and can change motion.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>3-PS2-1.</em> Objects in contact exert forces on
                        each other.
                      </li>
                      <li>
                        <em>3-PS2-3.</em> Electric and magnetic forces between a
                        pair of objects do not require the objects to be in
                        contact.
                      </li>
                      <li>
                        <em>3-PS2-4.</em> The sizes of the forces in each
                        situation depend on the properties of the objects and
                        their distance apart and, for forces between two
                        magnets, on their orientation relative to each other.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS2-3.</em> Electric and magnetic forces can be
                        attractive or repulsive, and their sizes depend on the
                        magnitudes of the charges, currents, or magnetic
                        strengths involved and on the distance between the
                        interacting objects.
                      </li>
                      <li>
                        <em>MS-PS2-5.</em> Forces that act at a distance can be
                        explained by fields that extend through space and can be
                        mapped by their effect on a test object.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS2-4.</em> Forces at a distance are explained by
                        fields permeating space than can transfer energy through
                        space.
                      </li>
                      <li>
                        <em>HS-PS2-5.</em> Magnets or electric currents cause
                        magnetic field; electric charges or changing magnetic
                        fields cause electric fields.
                      </li>
                      <li>
                        <em>HS-PS2-6.</em> Attraction and repulsion between
                        electric charges at the atomic scale explain the
                        structure, properties, and the constant forces between
                        material objects.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck4"></input>
                  <label className="label" for="chck4">
                    PS3.C: Relationship Between Energy and Forces
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>K-PS2-1.</em> A bigger push or pull makes things go
                        faster.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>4-PS3-3.</em> When objects collide, the contact
                        forces transfer energy so as to change the objects'
                        motions.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS3-2.</em> When two objects interact, each one
                        exerts a force on the other that can cause energy to be
                        transferred to or from the object.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS3-5.</em> When two objects interacting through
                        a field change relative position, the energy stored in
                        the field is changes.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Column>
          {/* PI team still trying to decide if we're going 
              to use these callouts prior to site launch
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="career-right-image">
                <Image filename="DiffusionWithMiss-Profile-Square.jpg" />
              </div>
              <div className="global__profile-content">
                <h3>Camille Schrier</h3>
                <h4>Miss America 2020 & 3M Scientist</h4>
              </div>
            </div>
          </Column> */}
        </Row>
      </Container>
    </Section>
    {/* Explore Additional Science at Home Videos and Activities */}
    <Section className="science-at-home__cards pb-6">
      <Container>
        <Row>
          <Column fullWidth>
            <h2>Explore Additional Science at Home Videos and Activities</h2>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="PushAndPull-Thumbnail.png" />
              <div className="card__content">
                <h3>Push and Pull</h3>
                <p>
                  Follow along with 3M’s Sam Reiss, as he shows you that
                  magnetism is more than just a simple push and pull – it’s an
                  example of the power of the earth itself.
                </p>
              </div>
              <Link to="../push-and-pull" className="button bg-gradient-purple">
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="FeelingSound-Thumbnail.png" />
              <div className="card__content">
                <h3>Feeling Sound</h3>
                <p>
                  Believe it or not, you can feel sound! Join Gitanjali Rao,
                  former Discovery Education 3M Young Scientist Challenge
                  winner, as she teaches about the frequency of sound and how we
                  perceive pitch.
                </p>
              </div>
              <Link to="../feeling-sound" className="button bg-gradient-purple">
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="InflationStation-Thumbnail.png" />
              <div className="card__content">
                <h3>Inflation Station</h3>
                <p>
                  Follow along with 3M’s Chief Science Advocate, Jayshree Seth,
                  as she teaches students how chemistry can help put some air
                  where it’s most needed!
                </p>
              </div>
              <Link
                to="../inflation-station"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default DiffusionWithMissAmerica2020;
